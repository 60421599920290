import { useFormik } from 'formik'
import { decode } from 'html-entities'
import ReactQuill from 'react-quill'
import * as yup from 'yup'
import { Container, Grid, TextField } from '@mui/material'
import Dialog from 'components/dialog/dialog'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import { useGetTermsAndConditionDetailQuery, useUpdateTermsAndConditionMutation } from 'store/terms'
import type { PartialTermsDetailDataProps, PartialTermsDetailProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import DialogStyle from './style'
import 'react-quill/dist/quill.snow.css'

const Edit = ({ open, id, onClose }: DetailStateProps & { id: string }) => {
  if (!open) return <></>

  const { data, isFetching, isLoading } = useGetTermsAndConditionDetailQuery({ key: id })
  const [updateTermsAndCondition, update] = useUpdateTermsAndConditionMutation()

  const scheme = yup.object<PartialTermsDetailDataProps>({
    key: yup.string().required('Key is required'),
    data: yup.array().of(
      yup.object({
        id: yup.number().required('Id is required'),
        language: yup.string().required('Language is required'),
        body: yup.string()
      })
    )
  })

  const formik = useFormik<PartialTermsDetailProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      key: (data && data.key) || '',
      data: [
        {
          id: (data && data.data && data.data[0] && data.data[0].id) || 0,
          language: (data && data.data && data.data[0] && data.data[0].language) || '',
          body:
            (data &&
              data.data &&
              data.data[0] &&
              // new TextDecoder().decode(base64ToBytes(data.data[0].body))) ||
              decode(data.data[0].body)) ||
            ''
        },
        {
          id: (data && data.data && data.data[1] && data.data[1].id) || 0,
          language: (data && data.data && data.data[1] && data.data[1].language) || '',
          body:
            (data &&
              data.data &&
              data.data[1] &&
              // new TextDecoder().decode(base64ToBytes(data.data[1].body))) ||
              decode(data.data[1].body)) ||
            ''
        }
      ]
    },
    onSubmit: (values: PartialTermsDetailProps) => {
      // const dataId = values.data.find((d) => d.language === 'ID')?.body ?? ''

      // const test1 = bytesToBase64(new TextEncoder().encode(dataId))
      // const test2 = new TextDecoder().decode(base64ToBytes(test1))

      updateTermsAndCondition({
        ...values,
        data: values.data.map((el) => ({
          ...el,
          body: GLOBAL.bytesToBase64(new TextEncoder().encode(el.body))
        }))
      })
    }
  })

  const loading = (isLoading || isFetching) && !data

  const lang = ['EN-US', 'ID']

  const gridDataLabel = ['Language']
  const gridDataValue = ['language']

  return (
    <>
      <Dialog
        title='Update Terms and Condition'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={update.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          {loading && <Loading />}
          {!loading && (
            <>
              <TextField
                id='key'
                variant='outlined'
                label='Terms and Condition Name'
                value={formik.values.key}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                inputProps={{ readOnly: true }}
                error={formik.touched && formik.touched.key && Boolean(formik.errors.key)}
                helperText={
                  formik.touched && formik.touched.key && formik.errors && formik.errors.key
                }
                fullWidth
              />
              {lang.map((_, index) => (
                <>
                  <Grid container spacing={2}>
                    {gridDataLabel.map((title, id) => (
                      <Grid key={id} item xs={12}>
                        <TextField
                          variant='outlined'
                          name={title}
                          label={title}
                          value={
                            (formik.values.data &&
                              formik.values.data[index][
                                gridDataValue[id] as keyof PartialTermsDetailDataProps
                              ]) ||
                            '-'
                          }
                          inputProps={{ readOnly: true }}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <ReactQuill
                    theme='snow'
                    id={`data[${index}].body`}
                    onChange={(e) =>
                      formik.setFieldValue(`data[${index}].body`, GLOBAL.emptyQuill(e))
                    }
                    value={formik.values.data && formik.values.data[index].body}
                  />
                </>
              ))}
            </>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default Edit
