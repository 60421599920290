import { FieldArray, FormikProvider, useFormik } from 'formik'
import ReactQuill from 'react-quill'
import * as yup from 'yup'
import { Container, Stack, TextField } from '@mui/material'
import Dialog from 'components/dialog/dialog'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import { useCreateTermsAndConditionMutation } from 'store/terms'
import type { PartialTermsDetailDataProps, PartialTermsDetailProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import DialogStyle from './style'

import 'react-quill/dist/quill.snow.css'

const Create = ({ open, onClose }: DetailStateProps) => {
  const [createTermsAndCondition, create] = useCreateTermsAndConditionMutation()

  const scheme = yup.object<PartialTermsDetailDataProps>({
    key: yup.string().required('Key is required'),
    data: yup.array().of(
      yup.object({
        language: yup.string().required('Language is required'),
        body: yup.string()
      })
    )
  })

  const formik = useFormik<PartialTermsDetailProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      key: '',
      data: [
        { language: 'ID', body: '' },
        { language: 'EN-US', body: '' }
      ]
    },
    onSubmit: (values: PartialTermsDetailProps) =>
      createTermsAndCondition({
        ...values,
        data: values.data.map((el) => ({
          ...el,
          body: GLOBAL.bytesToBase64(new TextEncoder().encode(el.body))
        }))
      })
  })

  return (
    <>
      <Dialog
        title='Create New Terms and Condition'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          <TextField
            id='key'
            variant='outlined'
            label='Terms and Condition Name'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched && formik.touched.key && Boolean(formik.errors.key)}
            helperText={formik.touched && formik.touched.key && formik.errors && formik.errors.key}
            fullWidth
          />
          <FormikProvider value={formik}>
            {formik.values.data.map((_, index) => (
              <FieldArray
                key={index}
                name='data'
                render={() => (
                  <Stack {...DialogStyle.Stack}>
                    <TextField
                      id={`data[${index}].language`}
                      variant='outlined'
                      label='Language'
                      value={formik.values.data[index].language}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      inputProps={{ readOnly: true }}
                      fullWidth
                    />
                    <ReactQuill
                      theme='snow'
                      id={`data[${index}].body`}
                      onChange={(e) =>
                        formik.setFieldValue(`data[${index}].body`, GLOBAL.emptyQuill(e))
                      }
                    />
                  </Stack>
                )}
              />
            ))}
          </FormikProvider>
        </Container>
      </Dialog>

      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Create
